import '../../styles/contact-details.scss';

import { Typography } from 'antd';
import React, { memo } from 'react';

import { useConfigData } from '../../data';
import { Box } from '../layout';
import { richTextToReactComponents } from '../utils';

interface ContactDetailsProps {
  showAddress?: boolean;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  showAddress = false,
}) => {
  const { data: configData } = useConfigData().fallback;

  return (
    <Box className="contact-details-box">
      {showAddress && (
        <Box className="address-details">
          <Typography.Paragraph>{configData.companyName}</Typography.Paragraph>
          {richTextToReactComponents(configData.address)}
        </Box>
      )}
      <Box>
        <a href={`tel:${configData.phoneNumber}`}>{configData.phoneNumber}</a>
      </Box>
      <Box>
        <a href={`mailto:${configData.email}`}>{configData.email}</a>
      </Box>
    </Box>
  );
};

export default memo(ContactDetails);
