import '../styles/footer.scss';

import { Typography } from 'antd';
import React from 'react';

import { useConfigData } from '../data';
import ContactDetails from './common/contact-details';
import EnhancedLink from './common/enhanced-link';
import { Box, Col, Container, Row, Section } from './layout';
import SocialMedias from './social-medias';
import { richTextToReactComponents } from './utils';

const Footer: React.FC = () => {
  const { data: configData } = useConfigData().fallback;

  return (
    <Section className="footer-section">
      <Container>
        <Row>
          <Col span={{ sm: 24, md: 16 }}>
            <Row>
              {configData.footerNavigation.map((item, index) => (
                <Col key={index} span={{ sm: 24, md: 8 }}>
                  <EnhancedLink key={index} item={item} unwrapSubRoutes />
                </Col>
              ))}
            </Row>
          </Col>

          <Col span={{ sm: 24, md: 8 }}>
            <Typography.Paragraph>Kontakt</Typography.Paragraph>
            <Box className="contact-data">
              <Typography.Paragraph>
                {configData.companyName}
              </Typography.Paragraph>

              {richTextToReactComponents(configData.address)}

              <ContactDetails />
            </Box>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col span={{ sm: 24, md: 12 }}>
            <Typography.Paragraph className="copyright">
              ©{` `}
              {new Date().getFullYear()}
              {` `}
              {configData.companyName}
            </Typography.Paragraph>
          </Col>
          <Col span={{ sm: 24, md: 12 }}>
            <Box justify="end">
              <SocialMedias />
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default Footer;
