import React from 'react';

const FacebookIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={23}
    viewBox="0 0 13 23"
    fill="none"
  >
    <path
      d="M12 1.53857H9C7.67392 1.53857 6.40215 2.06536 5.46447 3.00304C4.52678 3.94072 4 5.21249 4 6.53857V9.53857H1V13.5386H4V21.5386H8V13.5386H11L12 9.53857H8V6.53857C8 6.27336 8.10536 6.019 8.29289 5.83147C8.48043 5.64393 8.73478 5.53857 9 5.53857H12V1.53857Z"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FacebookIcon;
