import { createContext } from 'react';

import { PageData, StaticPageId } from '../data';

export interface PageLocalContextInterface {
  id: string;
  pageId: StaticPageId;
  data: PageData['data'];
}

export const PageLocalContext = createContext<
  Partial<PageLocalContextInterface>
>({});
