import '../styles/social-medias.scss';

import React, { memo } from 'react';

import { useConfigData } from '../data';
import FacebookIcon from './icons/facebook';
import InstagramIcon from './icons/instagram';
import { Box } from './layout';

const SOCIAL_MEDIA_ICONS = {
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
};

const SocialMedias: React.FC = () => {
  const { data } = useConfigData().fallback;

  return (
    <Box className="social-medias">
      {data.socialMedias.map((item, index) => (
        <a
          className="social-medias__item"
          key={index}
          href={item.url}
          target="_blank"
          aria-label={item.name}
          rel="noreferrer"
        >
          {SOCIAL_MEDIA_ICONS[item.name.toLowerCase()]}
        </a>
      ))}
    </Box>
  );
};

export default memo(SocialMedias);
