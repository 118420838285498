import '../../styles/enhanced-link.scss';

import { Dropdown, Typography } from 'antd';
import { Link } from 'gatsby';
import React, { memo, useContext } from 'react';

import { PageLocalContext } from '../../context';
import { NavigationConfig } from '../../data';
import { useLinkResolver } from '../../hooks';
import { classNameMap } from '../../utils';
import DropdownArrowIcon from '../icons/dropdown-arrow';

interface EnhancedLinkProps {
  item: NavigationConfig;
  unwrapSubRoutes?: boolean;
}

const EnhancedLink: React.FC<EnhancedLinkProps> = ({
  item,
  unwrapSubRoutes = false,
}) => {
  const linkResolver = useLinkResolver();
  const pageLocalContext = useContext(PageLocalContext);

  const makeLink = item => (
    <Link
      activeClassName="active"
      className="link item"
      aria-label={item.title}
      partiallyActive
      key={item.link}
      to={linkResolver(item.link)}
    >
      {item.title}
    </Link>
  );

  const makeLinkSubRoutes = () => {
    const menu = item.subRoutes?.map((child, index) => (
      <div key={index} className="link__subroutes-menu-item">
        {makeLink(child)}
      </div>
    ));

    if (unwrapSubRoutes) {
      return (
        <>
          <Typography.Paragraph className="group-title">
            {item.title}
          </Typography.Paragraph>
          <div>{menu}</div>
        </>
      );
    }

    return (
      <Dropdown overlay={<div className="dropdown-menu">{menu}</div>}>
        <button
          className={classNameMap({
            link: true,
            item: true,
            active: item?.subRoutes
              .map(subRoute => subRoute.link)
              .includes(pageLocalContext.pageId),
          })}
          aria-label={item.title}
        >
          {item.title}
          <DropdownArrowIcon />
        </button>
      </Dropdown>
    );
  };

  return item?.subRoutes ? makeLinkSubRoutes() : makeLink(item);
};

export default memo(EnhancedLink);
