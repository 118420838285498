import React from 'react';

const InstagramIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M17 2.92529H7C4.23858 2.92529 2 5.16387 2 7.92529V17.9253C2 20.6867 4.23858 22.9253 7 22.9253H17C19.7614 22.9253 22 20.6867 22 17.9253V7.92529C22 5.16387 19.7614 2.92529 17 2.92529Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 12.2951C16.1234 13.1274 15.9812 13.9773 15.5937 14.7241C15.2062 15.4709 14.5931 16.0765 13.8416 16.4548C13.0901 16.833 12.2384 16.9647 11.4077 16.831C10.5771 16.6974 9.80971 16.3052 9.21479 15.7103C8.61987 15.1153 8.22768 14.348 8.09402 13.5173C7.96035 12.6867 8.09202 11.835 8.47028 11.0835C8.84854 10.332 9.45414 9.71885 10.2009 9.33135C10.9477 8.94385 11.7977 8.80169 12.63 8.92511C13.4789 9.05099 14.2648 9.44657 14.8716 10.0534C15.4785 10.6603 15.8741 11.4462 16 12.2951Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 7.42529H17.51"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InstagramIcon;
