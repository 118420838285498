import '../styles/navigation.scss';

import { Drawer } from 'antd';
import { Link } from 'gatsby';
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { PageLocalContext } from '../context';
import { DataType, useConfigData } from '../data';
import { win } from '../fragments/window';
import { chunk } from '../utils';
import EnhancedLink from './common/enhanced-link';
import CloseIcon from './icons/close';
import HamburgerIcon from './icons/hamburger';
import PhoneIcon from './icons/phone';
import Image from './image';
import { Box, Col, Container, Row } from './layout';

const Navigation: React.FC = () => {
  const [isDrawerOpened, setDrawerOpened] = useState<boolean>(false);
  const config = useConfigData().fallback.data;
  const headerElementRef = useRef<HTMLDivElement>();
  const pageLocalContext = useContext(PageLocalContext);

  const navigationItems = [...config.mainNavigation];

  const halfNavigation = navigationItems.length / 2;
  const [navPartOne, navPartTwo] = chunk(config.mainNavigation, halfNavigation);

  useEffect(() => {
    if (headerElementRef.current && win) {
      win.document.addEventListener('scroll', () => {
        if (win.scrollY > 950) {
          headerElementRef.current?.classList.add('header-sticky');
        }

        if (win.scrollY < 50) {
          headerElementRef.current?.classList.remove('header-sticky');
        }
      });
    }
  }, [headerElementRef?.current]);

  const hasHeroSection = useMemo(
    () =>
      pageLocalContext?.data?.sections?.some(
        section => section.type === DataType.HeroSection,
      ) ?? false,
    [pageLocalContext],
  );

  useEffect(() => {
    if (hasHeroSection === false && headerElementRef.current) {
      headerElementRef.current.style.position = 'static';
    } else {
      headerElementRef.current.style.position = 'absolute';
    }
  }, [headerElementRef?.current, pageLocalContext, win?.scrollY]);

  return (
    <header ref={headerElementRef}>
      <Container>
        <Row align="middle" justify="space-between" style={{ height: '100%' }}>
          <Col span={{ xl: 7 }} hidden={{ sm: true, xl: false }}>
            <Box className="navigation-desktop">
              {navPartOne.map((item, index) => (
                <EnhancedLink key={index} item={item} />
              ))}
            </Box>
          </Col>

          <Col span={{ sm: 8 }} hidden={{ sm: false, xl: true }}>
            <Box align="middle">
              <a
                href={`tel:${config.phoneNumber}`}
                className="contact-telephone"
                aria-label="contact telephone"
              >
                <PhoneIcon />
              </a>
            </Box>
          </Col>

          <Col
            span={{ sm: 8, md: 6, xl: 10 }}
            style={{ textAlign: 'center' }}
            center
          >
            <Link
              style={{ display: 'block', lineHeight: '0' }}
              to="/"
              aria-label="company logo"
            >
              <Image id={config.logo.id} />
            </Link>
          </Col>

          <Col span={{ xl: 7 }} hidden={{ sm: true, xl: false }}>
            <Box className="navigation-desktop">
              {navPartTwo.map((item, index) => (
                <EnhancedLink key={index} item={item} />
              ))}
            </Box>
          </Col>

          <Col hidden={{ sm: false, xl: true }} span={{ sm: 8, md: 6 }}>
            <Box align="middle" fullHeight justify="end">
              <div
                className="hamburger-btn btn"
                tab-index="0"
                onClick={() => setDrawerOpened(true)}
              >
                <HamburgerIcon />
              </div>
            </Box>
          </Col>
        </Row>
      </Container>

      <Box className="navigation-mobile" hidden={{ sm: false, xl: true }}>
        <Drawer
          placement="right"
          closable={false}
          width="100%"
          visible={isDrawerOpened}
        >
          <div className="drawer-head">
            <div className="drawer-head__company-logo">
              <Link to="/" aria-label="company logo">
                <Image id={config.logo.id} />
              </Link>
            </div>

            <div
              className="close-btn btn"
              tab-index="0"
              onClick={() => setDrawerOpened(false)}
            >
              <CloseIcon />
            </div>
          </div>

          <div className="drawer-body">
            {config.mainNavigation.map((item, index) => (
              <EnhancedLink key={index} item={item} unwrapSubRoutes />
            ))}
          </div>
        </Drawer>
      </Box>
    </header>
  );
};

export default memo(Navigation);
