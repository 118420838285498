import React from 'react';

const DropdownArrowIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={10}
    viewBox="0 0 16 10"
    fill="none"
  >
    <path d="M2 1.5L8 7.5L14 1.5" stroke="#F9F9F9" strokeWidth={3} />
  </svg>
);

export default DropdownArrowIcon;
