import '../styles/boilerplate.scss';

import React, { useEffect } from 'react';

import { PageTheme, PageThemeMode } from '../data';
import { useConfigData } from '../data/hooks/use-config-data';
import { win } from '../fragments';
import { classNameMap } from '../utils';
import Footer from './footer';
import Navigation from './navigation';
import PageSeo from './page-seo';

interface BoilerplateProps {
  pageId: string;
  seoId: string;
  pageTheme?: PageTheme;
}

const Boilerplate: React.FC<BoilerplateProps> = ({
  children,
  seoId,
  pageId,
  pageTheme,
}) => {
  const config = useConfigData().fallback.data;

  useEffect(() => {
    initializeTheme();
  }, []);

  const initializeTheme = async () => {
    if (win) {
      const WebFontLoader = await import('webfontloader');
      await WebFontLoader.load({
        google: {
          families: config.theme.fontFamilies,
        },
      });

      const rootElement = win?.document.querySelector('html');
      const cssVariablesDefinition = [
        {
          name: '--theme-primary',
          value: config.theme.primaryColor,
        },
        {
          name: '--theme-on-primary',
          value: config.theme.onPrimaryColor,
        },
        {
          name: '--theme-secondary',
          value: config.theme.secondaryColor,
        },
        {
          name: '--theme-on-secondary',
          value: config.theme.onSecondaryColor,
        },
        {
          name: '--theme-text-color',
          value: config.theme.textColor,
        },
        {
          name: '--theme-font-primary',
          value: config.theme.fontFamilies[0].split(':')[0],
        },
      ];

      cssVariablesDefinition.forEach(({ name, value }) =>
        rootElement.style.setProperty(name, value),
      );
    }
  };

  return (
    <div
      className={classNameMap({
        boilerplate: true,
        [`boilerplate__navigation--${
          pageTheme?.navigationMode ?? PageThemeMode.Light
        }`]: true,
        [`boilerplate__background--${
          pageTheme?.backgroundMode ?? PageThemeMode.Light
        }`]: true,
      })}
    >
      <PageSeo id={seoId} />
      <Navigation />
      <main id={pageId}>{children}</main>
      <Footer />
    </div>
  );
};

export default Boilerplate;
