import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { useImageData } from '../data';
import { isNil } from '../utils';

interface ImageProps {
  id: string;
}

export const Image: React.FC<ImageProps> = ({ id }) => {
  const data = useImageData(id);

  return !isNil(data?.data?.bitmap?.fluid) ? (
    <GatsbyImage
      style={{ height: '100%', width: '100%' }}
      image={data?.data?.bitmap.fluid}
      alt={data?.data?.alt}
    />
  ) : !isNil(data?.data?.vector?.url) ? (
    <img src={data?.data?.vector?.url} alt={data?.data?.alt} />
  ) : (
    <></>
  );
};

export default Image;
